import React from "react";
import userGuideImage from "../../../images/receipt-user-guide.png";

const ReceiptsUserGuideModal = () => {
  return (
    <>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-bottom-0">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-center py-0">
              <img src={userGuideImage} alt="user-guide" className="receipt-user-guide-img" height={420}/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReceiptsUserGuideModal;
