import React, { useLayoutEffect } from "react"
import Form from "../form/form"
import './landing.css'

const Landing = () => {
  function getDocumentHeight() {
    const element = document.getElementById("___gatsby");
    if (element) {
      window.parent.postMessage({
        height: element.clientHeight
      }, "*");
      // console.log(element.clientHeight);
    }
  }

  // function checkVisible() {
  //   var ele = document.getElementById("disc-container")
  //   if (ele) {
  //     var rect = ele.getBoundingClientRect();
  //     var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
  //     if (!(rect.bottom < 0 || rect.top - viewHeight >= 0)) {
  //       console.log("disclainer is visible");
  //       getDocumentHeight();
  //     }
  //   }
  // }

  useLayoutEffect(() => {
    window.addEventListener('resize', getDocumentHeight);
    // window.addEventListener('scroll', checkVisible);
    getDocumentHeight();
    return () => {
      window.removeEventListener('resize', getDocumentHeight);
      // window.removeEventListener('scroll', checkVisible)
    }
  }, []);

  return (
    <div className="form-layout">
      <Form />
    </div>
  )
}

export default Landing
