import React, {useEffect, useRef} from "react";
import brandImg from "../../../images/brand-img.png";
import { useState } from "react";
const ThanksMessage = (props) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const ref = useRef()

  const onLoad = () => {
    window.dispatchEvent(new Event("resize"));
    setImageLoaded(true);
  }

  useEffect(() => {
    window.dispatchEvent(new Event("resize"));
    if (ref.current && ref.current.complete) {
      window.dispatchEvent(new Event("resize"));
    }
  }, [ref, imageLoaded]);

  return (
    <div className="thanks-page-styles">
      {!props.invalidReceiptErrorMsg &&
        !props.oneDayValidationErrorMsg &&
        !props.duplicateWinnerErrorMsg && (
          <>
            <h1 className="thanks-header">Thank you</h1>
            <p className="thanks-message">
              Thanks for claiming your 2-for1 Funlab Coupon!
            </p>
          </>
        )}
      {props.invalidReceiptErrorMsg && (
        <p className="error-info-msg">
          Unfortunately, our system was unable to validate your receipt;
          however, our team will manually check it within the next 7 days. If
          deemed valid, you will receive an email to your nominated email
          address with your 2-for-1 Funlab Coupon, which you can redeem at any
          of the Funlab venues listed below:
        </p>
      )}
      {props.oneDayValidationErrorMsg && (
        <div className="error-info-one-day-validation">
          <h2 className="text-center">Not this time...</h2>
          <h4>
            Unfortunately, you didn't win this time. Please feel free to enter
            again with your next purchase.
          </h4>
          <hr />
          <p>
          Don’t forget, you are also in the draw to WIN 1 of 5 $5,000 FUN
          weekends for two*.
          </p>
          <p>
            Major prize draw is on 6th June 2024 at 2.00pm AEST.
          </p>
        </div>
      )}
      {props.duplicateWinnerErrorMsg && (
        <div className="error-info-one-day-validation">
          <h4 className="text-center mt-1 mb-3">THANKS FOR ENTERING</h4>
          <p className="followup-message1">
            As you have already received your 2-for-1 Funlab voucher, you are
            not eligible to recieve another one, however your additional entry
            has been included in our Major Prize draw which gives you the chance
            to WIN 1 of 5 Fun Weekends (valued at $5,000)*.
          </p>
          <p>
            The Major Prize draw will take place on 6th June 2024 at 2.00pm AEST.
          </p>
        </div>
      )}
      <div className="followup-wrapper">
        {!props.invalidReceiptErrorMsg &&
          !props.oneDayValidationErrorMsg &&
          !props.duplicateWinnerErrorMsg && (
            <p className="followup-message1">
              You will shortly receive an email to your nominated email address
              with your 2-for-1 Funlab Coupon to redeem at any of the below
              Funlab venues:
            </p>
        )}
        <div className="brand-logo">
          <img
            src={brandImg}
            ref={ref}
            onLoad={onLoad}
            alt="brand-img"
            className="brand-img"
          />
        </div>
        {!props.oneDayValidationErrorMsg && !props.duplicateWinnerErrorMsg && (
          <div className="followup-message2">
            <p>
              Don’t forget, you are also in the draw to WIN 1 of 5 $5,000 FUN
              weekends for two*.
            </p>
            <p>Major prize draw is on 6th June 2024 at 2.00pm AEST.</p>
          </div>
        )}
        <button
          className="home-btn"
          onClick={() => {
            window.location.reload();
          }}
        >
          Home
        </button>
      </div>
    </div>
  );
};

export default ThanksMessage;
