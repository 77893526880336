import React from "react"
import Seo from "../components/seo"
import Landing from "../components/landing/landing"
// import NotFoundPage from "./404"

const LandingPage = () => {

  return (
    <>
      <Seo title="Eclipse Twice the Fun" />
      {/* <Entry /> */}
      <Landing />
    </>

    // <Layout>
    //   <>
    //     <Seo title="Promotion Closed | Eclipse Twice the Fun" />
    //     <Late />
    //   </>
    // </Layout>
  )
}

export default LandingPage
